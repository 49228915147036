import {
    Box,
    Button,
    Center,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    Container,
  } from '@chakra-ui/react'
import { useDisclosure} from '@chakra-ui/react'
import axios from 'axios'

  export default function ConfirmDeleteAccountModal({sessionId, logOut}) {
    function confirm_delete(){
      let req = axios.post('https://api.noteefy.net/delete_account/', [sessionId] )
      logOut()
      toast({
        title: 'Account has been deleted.',
        description: 'Your account has been deleted successfuly',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }


    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const abc = function(e){
        e.stopPropagation()
        onOpen()
    }
    return (
      <>
      <Box borderRadius = {7} p={4} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
        <Center>
        <Button width={'80%'} onClick={abc} 
          backgroundColor='brand.badgePerformanceElement'>Delete account
        </Button>
        </Center>
      </Box>       
      
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm account deletion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Container>
            <Text>Are you sure that you want to delete your Noteefy account?</Text><br/>
            <Text>If you want to get rid of mail notifications, you can just click the Turn off notifications button</Text><br/>
            
            </Container>
            </ModalBody>
  
            <ModalFooter>
            <Button backgroundColor='brand.badgePerformanceElement' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button variant='ghost' onClick={confirm_delete}>Delete</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

