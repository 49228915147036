import React from 'react'
import { Image, Text, Center, SimpleGrid, Tooltip} from '@chakra-ui/react';


function Rating({value, fontSize}){
  return(
    <Text fontSize={fontSize} as='b'>{value}</Text>
  )
}

function RatingProgress({progress, fontSize}){
  let progress_color = 'grey'
  let prefix = '+'
  if (progress>0){
    progress_color = 'green'
  } else if (progress < 0){
    progress_color = 'red'
    prefix = ''
  }
  return(
    <Text as='b' color={progress_color} fontSize={fontSize} >{prefix + String(progress)}</Text>
  )
}

function check_one_game(game_name, data){
  if(game_name in data){
    return [data[game_name]['rating'], data[game_name]['prog']]
  }
  else{
    return [0, 0]
  }
}


export default function BestRatingIndicator({data, fontSize, width, height}) {
  let progress = 0
  let rating = 0
  let icon_url = ''
  let tooltip_text = ''

  let [bullet_rating, bullet_progress] = check_one_game('bullet', data)
  let [blitz_rating, blitz_progress] = check_one_game('blitz', data)
  let [rapid_rating, rapid_progress] = check_one_game('rapid', data)

  if(bullet_rating>= blitz_rating){
    if(bullet_rating>= rapid_rating){
      rating = bullet_rating
      progress = bullet_progress
      icon_url = 'https://api.noteefy.net/static/bullet.png'
      tooltip_text = 'Bullet'
      
    }
    else{
      rating = rapid_rating
      progress = rapid_progress
      icon_url = 'https://api.noteefy.net/static/rapid.png'

    }
  }
  else{
    if(blitz_rating>= rapid_rating){
      rating = blitz_rating
      progress = blitz_progress
      icon_url = 'https://api.noteefy.net/static/blitz.png'
      tooltip_text = 'Blitz'
    }
    else{
      rating = rapid_rating
      progress = rapid_progress
      icon_url = 'https://api.noteefy.net/static/rapid.png'
      tooltip_text = 'Rapid'

    }
  }
  
  return (
              <Center //box with the badge
                  borderRadius={10}
                  width={width}
                  height={height}
                  boxShadow="md"
                  backgroundColor={'brand.badgePerformanceElement'}
              >
                <SimpleGrid columns={3} width={'100%'} height={'100%'} align={'center'}>
                <Center>
                <Rating value={rating} fontSize={fontSize}/>
                </Center>

                <Center>
                <RatingProgress progress={progress} fontSize={fontSize}/>
                </Center>

                <Center>
                <Tooltip label={tooltip_text}>
                <Image src={icon_url} boxSize={height/1.1}/>
                </Tooltip>
                </Center>
                
              </SimpleGrid>
              </Center>

  )
}

