import { Link } from '@chakra-ui/react'
import {  Icon, Flex, Divider, Spacer, Grid, Button, Center, VStack, HStack, Image} from '@chakra-ui/react';
import React, { useState } from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tooltip,Circle} from '@chakra-ui/react';

import GameBadge from "./GameBadge"
import BestRatingIndicator from "./BestRatingIndicator"
import NotificationsSettings from "./NotificationsSettings"


export default function PlayerBadge(params) {
  const global_width = params.isMobile? params.window_width/1.1 : Math.min(params.window_width/3.7, 600)
  const badge_width = global_width / 1.1
  const hw_ratio = 1.3
  const global_height = hw_ratio * global_width
  const fontSize  = global_height/45
  const column_width = global_width/2.3
  const game_badge_width = column_width/3.2
  const small_elements_border_radius = global_width/70
  const [showBadge, setShowBadge] = useState(params.showBadge)
  let header_items_height = 45
  if (params.isMobile){
    header_items_height = 33 // looks like i should by dividable by 3 due to the image box size -> int?
  }

  

  
  const button_width = global_width/7
  const button_height = global_height/24

  const elements_spacing = 2

  let localSettings = params.data['settings']
  const [online, setOnline] = useState(localSettings[0])
  const [playing, setPlaying] = useState(localSettings[1])
  const [finished, setFinished] = useState(localSettings[2])

let country = params.data.country  

  const remove_player = function(){   
    let new_data = params.badges
    delete new_data[params.nick.toLowerCase()]
    delete new_data[params.nick]
    params.handleUpdateBadges(new_data)
  }

  function changeShow(){
     setShowBadge(!showBadge)
  }

  const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )

  function OnlineIcon({is_online, is_playing}){
    if (is_playing == true){
      return(<CircleIcon color='red.600'/>)
    }
    if (is_online == true){
      return(<CircleIcon color='green.300'/>)
    }
    else return null
  }

  function Rating({value, fontSize}){
    return(
      <Text fontSize={fontSize}>{value}</Text>
    )
  }
  
  function RatingProgress({progress, fontSize}){
    let progress_color = 'grey'
    let prefix = '+'
    if (progress>0){
      progress_color = 'green'
    } else if (progress < 0){
      progress_color = 'red'
      prefix = ''
    }
    return(
      <Text color={progress_color} fontSize={fontSize} >{prefix + String(progress)}</Text>
    )
  }
  
function toTitleCase(str) {
    return str
        .replace(/_/g, ' ') // replace underscores with spaces
        .replace(/([A-Z])|(\d+)/g, ' $1$2') // insert a space before all caps and the first number in a sequence
        .replace(/\b./g, function(str){ return str.toUpperCase(); }) // uppercase the first character of each word
}

function attributeText(str){
  //optionally return as a timestamp if there are only numbers and the length is 13
  //check if str starts with https
  if (typeof str === 'string' && str.startsWith('https')){
    return <Link href={str} isExternal color="blue.500">{str}</Link>
  }
  if (/^\d+$/.test(str) && String(str).length == 13){
    return timestamp_to_datetime(str, false)
  }
  return str
}

  function timestamp_to_datetime(seen_val, is_online){
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (is_online == true){
      return 'online'
    }
    return new Date(seen_val).toLocaleDateString('en-GB', options) + ' ' + new Date(seen_val).toLocaleTimeString('en-GB')
  }

  function timestamp_to_date(seen_val){
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (seen_val == 'online'){
      return 'online'
    }
    return new Date(seen_val).toLocaleDateString('en-GB', options)
  }

  function time_ago_indicator(dt){
    const now = Date.now()
    let diff = (now - dt)/1000
  
    let days = Math.floor(diff /60/ 60 /24)
    let hours = Math.floor(diff /60/ 60) - days * 24
    let minutes = Math.floor(diff /60) - (days * 24 + hours) * 60
    let seconds = Math.floor(diff) - ((days * 24 - hours) * 60 + minutes) * 60
    let suffix = ''
    let ret_text = ''
    if(days > 0){
      if(days > 1) suffix = 's'
      ret_text =  days + ' day'
    }else if (hours > 0){
      if(hours > 1) suffix = 's'
      ret_text =  hours + ' hour'
    } else if(minutes > 0){
      if(minutes > 1) suffix = 's'
      ret_text =  minutes + ' min'
    } else if (seconds > 0){
      ret_text =  seconds  + ' sec'
      if(seconds > 1) suffix = 's'
    }else{
      return ''
    }
    return ret_text + suffix + ' ago'
  }


  function HorizontalBadgeContainer(text, value){
    return (
    <Box width={column_width} p={elements_spacing} borderRadius={small_elements_border_radius} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
      <Flex>
      <Text fontSize={fontSize}>
        {text}:
      </Text>
      <Spacer/>
      <Text as='b' fontSize={fontSize}>
        {value}
      </Text>
      </Flex>
    </Box>)
  }


  function button_container(text, link, width, height){
    return (
      <Box width={'48%'} borderRadius = {small_elements_border_radius} p={elements_spacing} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
        <Center>
        <Link href={link} isExternal>
          <Button width={width} height={height} onClick={(e) => e.stopPropagation()} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>
            <Text fontSize={fontSize}>
            {text}
            </Text>
            </Button>
        </Link>
        </Center>
      </Box>)
  }

  function AllStatsAttributes(filtered_data, width) {
    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={2} width={width}>
        <Text fontWeight="bold" fontSize={fontSize}>Attribute</Text>
        <Text fontWeight="bold" fontSize={fontSize}>Value</Text>
        {Object.entries(filtered_data).filter(([key, value]) => typeof value !== 'object' && value !== '').flatMap(([key, value]) => [
          <Text key={key} fontWeight="bold" fontSize={fontSize}>{toTitleCase(key)}</Text>,
          <Text key={value} fontSize={fontSize}>{attributeText(value)}</Text>
        ])}
      </Grid>
    );
  }
  function AllStatsGames(filtered_data, width) {
    return (
      <Grid templateColumns="repeat(3, 1fr)" gap={2} width={width}>
            <Text fontWeight="bold" fontSize={fontSize}>Game</Text>
            <Text fontWeight="bold" fontSize={fontSize}>Games count</Text>
            <Text fontWeight="bold" fontSize={fontSize}>Rating</Text>
              {Object.entries(filtered_data).filter(([key, value]) => 
                  typeof value === 'object' && 
                  'games' in value &&  
                  value['rating'] > 0 &&
                  (params.chess_site !== 'Lichess' || value['games'] > 0)
              ).flatMap(([key, value]) => [                  <Text key={key + '1'} fontWeight="bold" fontSize={fontSize}>{toTitleCase(key)}</Text>,
              (params.chess_site === "Chesscom" && value['games'] === 0 ? 

            <Tooltip label="Chess.com does not provide information about number of games of this format." key={key + '2'}>
                <Box display="inline-flex" alignItems="center">
                    <Text fontSize={fontSize}>{value['games']}</Text>
                    <Circle size="20px" bg="gray.200" color="black" fontSize="sm">
                        ?
                    </Circle>
                </Box>
            </Tooltip>
              : 
              <Text key={key + '2'} fontSize={fontSize}>{value['games']}</Text>
            ),  
                <HStack>
                  <Rating key={key + '3'} value={value['rating']} fontSize={fontSize}/>,
                  <RatingProgress key={key + '4'} progress={value['prog']} fontSize={fontSize}/>
                </HStack>
              ])}
            </Grid>


    )}


function AllStats({params}){

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const handleButtonClick = () => setIsOpen(true);
  let keys_to_exclude = ['online', 'playing', 'settings', 'avatar', 'name', 'player_id', '@id', 'is_streamer', 'streaming_platforms', 'verified', 'url', 'was_online', 'online_changed', 'was_playing', 'playing_changed', 'seenAt']

  let filtered_data = Object.keys(params.data).filter(key => !keys_to_exclude.includes(key)).reduce((obj, key) => {
    obj[key] = params.data[key];
    return obj;
  }, {});
  let width = '50%'
  if (params.isMobile){
    width = '100%'
  }

  return (
    <Box width={'100%'} borderRadius={small_elements_border_radius} p={elements_spacing} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
      <Center>
        <Button backgroundColor='#F2E7D5' onClick={handleButtonClick} width={'70%'} height={button_height}>
          <Text fontSize={fontSize}>
            Show all statistics
          </Text>
        </Button>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay/>
        <ModalContent maxW={'80%'}>
          <ModalHeader>All Statistics</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

       { params.isMobile ? (
          <VStack p={5}>
            {AllStatsAttributes(filtered_data, width)}
            <Divider/>
            {AllStatsGames(filtered_data, width)}
          </VStack>
        ) : (
          <HStack p={5}>
            {AllStatsAttributes(filtered_data, width)}
            {AllStatsGames(filtered_data, width)}
          </HStack>
        )}

          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

  function RemoveButton({height}){
    return (
      <Box width={'100%'} borderRadius={small_elements_border_radius} p={elements_spacing} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
        <Center>
        <Button backgroundColor='#F2E7D5' onClick={remove_player} width={'45%'} height={height}>
          <Text fontSize={fontSize}>
          Remove
          </Text>
        </Button>
        </Center>
      </Box>)
  }

  function ShowHideButton({changing_func, width, height, image_box_size}){
    return (
      !showBadge?
      <Button width={width} height={height} onClick={changing_func} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>
        <TriangleDownIcon boxSize={image_box_size} />
        </Button>
      :
      <Button width={width} height={height} onClick={changing_func} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>
      <TriangleUpIcon boxSize={image_box_size} />
      </Button>
      )
  }
        
    function BadgeHeader(){

        return(
        <HStack p={elements_spacing}>
          <Center borderRadius={small_elements_border_radius} backgroundColor={'brand.beige'} width={header_items_height} height={header_items_height}>
          <Text fontSize={fontSize} as={'b'} color={'#393E46'}>{params.data.title}</Text>  
          </Center>
          <Text fontSize={fontSize} as={'b'} maxWidth={"30%"}> {params.nick}</Text>
          <OnlineIcon is_online={params.data.online} is_playing={params.data.playing}/>
          <Spacer/>
          {params.data.online ? null : <Text fontSize={fontSize/1.2  }> {time_ago_indicator(params.data.seenAt)}</Text>}
          <Flex width={1}/> 
          <BestRatingIndicator data={params.data} fontSize={fontSize} width={"25%"} height={header_items_height}/>
          <ShowHideButton changing_func={changeShow} width={header_items_height} height={header_items_height} image_box_size={header_items_height/3} />
      </HStack>)
    }

  return (
                    
              <Box //box with the badge
                  p={elements_spacing} // distance from the border - NOT MARGIN - margin is outside the box
                  borderRadius={small_elements_border_radius}
                  boxShadow="md"
                  width={badge_width}
                  // height={global_height}
                  backgroundColor={'brand.badgeBackground'}
              >
              <BadgeHeader isMobile = {params.isMobile}/>
              <Text fontSize='xs'>
              
              </Text>
              
                {showBadge ?
                <>
                <HStack>
                  <Box> {/*left side*/ }
                    <VStack >
                    <Box width={column_width} borderRadius={small_elements_border_radius} backgroundColor={'brand.badgePerformanceElement'} p={elements_spacing}>
                        <Center>
                        {params.chess_site == 'Chesscom' && params.data.avatar?
                          <Image src={params.data.avatar} boxSize={column_width/1.6} />:
                          <Image src='https://api.noteefy.net/static/Profile_picture.png' boxSize={column_width/1.6} />
                        }
                        </Center>
                    </Box>
                    
                    <Text fontSize={fontSize} as='b'>Profile information</Text>
                      {params.chess_site != 'Chesscom' ?  
                      HorizontalBadgeContainer('Name', params.data.firstName + ' ' + params.data.lastName):
                      HorizontalBadgeContainer('Name', params.data.name)}


                      {HorizontalBadgeContainer('Country', country)}

                      {HorizontalBadgeContainer('Joined', timestamp_to_date(params.data.createdAt))}
                      <Flex width={column_width}>
                      {button_container('TV', params.tv_link, button_width, button_height)}
                      <Spacer/>
                      {button_container('Profile', params.profile_link, button_width, button_height)}
                      </Flex>
                      <RemoveButton height={button_height}/>
                    </VStack>
                    
                    </Box>
                  <Box> {/*right side*/ }
                  <VStack>
                    <Box width={column_width} p={elements_spacing} borderRadius={small_elements_border_radius} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
                      <Text fontSize={fontSize}>
                        Last online:
                        <br/> 
                        <Text fontSize={fontSize} as='b'>{timestamp_to_datetime(params.data.seenAt, params.data.online)}</Text>
                      </Text>
                    </Box>
                  {HorizontalBadgeContainer('Games played',params.data.allGames)}
                  {/* {HorizontalBadgeContainer('Puzzle rating',params.data['puzzle']['rating'])} */}
                  
                  <Flex width={column_width}>
                        {GameBadge('Bullet', 'https://api.noteefy.net/static/bullet.png', params.data, 'bullet', game_badge_width, fontSize)}
                        <Spacer/>
                        {GameBadge('Blitz', 'https://api.noteefy.net/static/blitz.png', params.data, 'blitz', game_badge_width, fontSize)}
                        <Spacer/>
                        {GameBadge('Rapid', 'https://api.noteefy.net/static/rapid.png', params.data, 'rapid', game_badge_width, fontSize)}
                  </Flex>
                  <AllStats params={params}/>
                  
                  <NotificationsSettings player={params.nick} cookies={params.cookies} chess_site={params.chess_site} 
                  width={column_width}
                  online={online} setOnline={setOnline}
                  playing={playing} setPlaying={setPlaying}
                  finished={finished} setFinished={setFinished}
                  fontSize={fontSize}
                  />
                  </VStack>
                  </Box>
                </HStack>
                        {(params.data.firstName.length > 0 || params.data.lastName.length > 0) ?
                        //dataLine(params.isMobile, 'First name:', params.data.firstName, 'Last name:', params.data.lastName)
                        null
                        : null}

                        </>
                          : null}


              </Box>
  )
}

