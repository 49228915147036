import React from 'react'
import { useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom"
import { useCookies } from 'react-cookie'
import axios from 'axios';
import { ChakraProvider, extendTheme, Heading, Text, Box, Button, ButtonGroup, Editable,
Center,
Input,
Stack,
Spacer,
AbsoluteCenter,
useToast,
Flex,
} from '@chakra-ui/react'


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}




function ForgotPasswordPage(){
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  let {email} = useParams()
  const toast = useToast()
  const [errorText, setErrorText] = useState('')
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(true)
  const [writtenLogin, setWrittenLogin] = useState(email)
  function sendResetOrder(){
    if(writtenLogin.includes('@') && writtenLogin.includes('.')){
      let req = axios.post('https://api.noteefy.net/reset/' + writtenLogin )
    toast({
      title: 'We have sent you a password reset link.',
      description: 'We have sent you a password reset link. Check your e-mail and change your password.',
      status: 'success',
      duration: 3000,
      isClosable: true
    })
    setErrorText('')
    }
    else{
      setErrorText('Please write a valid e-mail address.')
    }
    
  }
  
  const handleLoginChange = (new_val) => setWrittenLogin(new_val.target.value);

  useEffect(()=>{
    if(windowHeight.current < windowWidth.current){
      setIsMobile(false)
    }
    else {setIsMobile(true)}
  }, [windowSize])

  
  return(
    <Center height={'80%'}>
  <Center>
        <Box p={10}
        width={600}
        height={350}
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md">
  <Stack
    flexDir="column"
    mb="2"
    justifyContent="center"
    alignItems="center"
  >
     <Heading size='lg'> Reset your password</Heading>
    <Box minW={{ base: "70%", md: "468px" }}>
        <Stack
          spacing={4}
          p="1rem"
          backgroundColor="whiteAlpha.900"
          boxShadow="md"
        >
              <Input type="email" 
              placeholder="email address" 
              defaultValue={email}
              onChange={handleLoginChange}/>
            <Button
            onClick={sendResetOrder}
            borderRadius={8}
            variant="solid"
            backgroundColor='#F2E7D5'
            width="full" 
          >
            Reset password
          </Button>
        </Stack>
    </Box>
        
        <Flex
        width={'100%'}>
          
        <Text color={'red'}>
          {errorText}
        </Text>
        </Flex>
        <Text>
        If you forgot your password, write your email address in the field above and click the "Reset password" button. You will receive an email with a link to set your new password.
        </Text>
      
    </Stack>
    </Box>
  
    </Center>
    </Center>
    
  )}

export default ForgotPasswordPage
