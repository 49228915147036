import React, { useEffect } from 'react'
import { Box, Image, Checkbox, Text, HStack} from '@chakra-ui/react';



export default function NotificationsSettings({player, width, cookies, chess_site, online, setOnline, playing, setPlaying, finished, setFinished, fontSize}) {
  let online_image_url = 'https://api.noteefy.net/static/online.png'
  let playing_image_url = 'https://api.noteefy.net/static/playing.png'
  let finished_image_url = 'https://api.noteefy.net/static/finished.png'

  const invertOnline = function(e){
    e.stopPropagation()
    setOnline(!online)
    }
  const invertPlaying = function(e){
    e.stopPropagation()
    setPlaying(!playing)}
  const invertFinished = function(e){
    e.stopPropagation()
    setFinished(!finished)}

  const handleSaveSettings = function(){
    let req = new XMLHttpRequest()
    req.open('POST', 'https://api.noteefy.net/settings/')
    req.send(JSON.stringify([cookies, player, chess_site, [online, playing, finished] ]))
}


useEffect(()=>{ 
if(online != undefined && playing != undefined && finished != undefined)
  {
    handleSaveSettings()
  }
}, [online, playing, finished])

const stop_click_propagation = function(e){
  e.stopPropagation()
}

  
  return (
              <Box onClick={stop_click_propagation}//box with the badge
                  p={2} // distance from the border - NOT MARGIN - margin is outside the box
                  borderRadius={10}
                  width={width}
                  boxShadow="md"
                  backgroundColor={'brand.lightSmallElement'}
              >

                <Text fontSize={fontSize} as={'b'}>Notification settings</Text>
                <Text fontSize={fontSize} >Get notification whether the player:</Text>
                
                <Checkbox defaultChecked={online} onChange={invertOnline}> <HStack><Image src={online_image_url} boxSize={4}/><Text fontSize={fontSize} >is online</Text></HStack></Checkbox>
                <br/>
                <Checkbox defaultChecked={playing} onChange={invertPlaying}> <HStack><Image src={playing_image_url} boxSize={4}/><Text fontSize={fontSize}> started playing</Text></HStack></Checkbox>
                <br/>
                <Checkbox defaultChecked={finished} onChange={invertFinished}> <HStack><Image src={finished_image_url} boxSize={4}/><Text fontSize={fontSize}>has finished playing</Text></HStack></Checkbox>

              </Box>

  )
}

