import React from 'react'
import {useCallback, useEffect, useState} from "react";
import {Box, Text, Icon, Image, HStack, Modal, Link, Button, Input, InputGroup, Tab, TabList, TabPanels, TabPanel, Tabs, VStack, Center, Spacer, Tooltip} from '@chakra-ui/react';
import axios from 'axios';
import PlayerBadge from "./PlayerBadge"
import { waitFor } from '@testing-library/react';
import { useCookies } from 'react-cookie'
import fetch from 'node-fetch';



const CircleIcon = (props) => (
  <Icon viewBox='0 0 200 200' {...props}>
    <path
      fill='currentColor'
      d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
    />
  </Icon>
)

function timestamp_to_datetime(seen_val){
  if (seen_val == 'online'){
    return 'online'
  }
  return new Date(seen_val).toLocaleTimeString('en-GB')
}



function Rating({value, progress}){
  let progress_color = 'grey'
  if (progress>0){
    progress_color = 'green'
  } else if (progress < 0){
    progress_color = 'red'
  }
  return(
    <HStack>
    <Text as='b'>{value}</Text>
    <Text color={progress_color}>{String(progress)}</Text>
    </HStack>
  )
}



function Event({data_string}){
  let tv_link = ''
  let profile_link = ''

  let url = 'https://api.noteefy.net/static/offline.png'
  let data_array = String(data_string).split(',')
  let site = data_array[0]
  let player = data_array[1]
  let state = data_array[2]
  let game_names = data_array[3]
  let game_counts = data_array[4]
  let new_ratings = data_array[5]
  let rating_changes = data_array[6]
  let timestamp = data_array[7]
  let text = 'is offline'
  let out_played_games_info = []
  if(site == 'Lichess'){
    tv_link = 'http://lichess.org/@/' + player.toLowerCase() + '/tv'
    profile_link = 'http://lichess.org/@/' + player.toLowerCase()
  }
  else if(site == 'Chesscom'){
    tv_link = 'https://www.chess.com/play/online?action=follow&member=' + player.toLowerCase()
    profile_link = 'https://www.chess.com/member/' + player.toLowerCase()
  }
  

  if(state == 1){
    text = 'is online'
    url = 'https://api.noteefy.net/static/online.png'
  } else if (state == 2){
    url = 'https://api.noteefy.net/static/finished.png'
    if(game_counts.length>0){
      text = 'has finished playing '
      let i = 0
      
      for (const temp of game_names.split('_'))
      { 
        let game_name = game_names.split('_')[i]
        let games_count = game_counts.split('_')[i]
        let new_rating = new_ratings.split('_')[i]
        let rating_change = rating_changes.split('_')[i]
        out_played_games_info.push(
        <Center>
        <HStack>
        <Text fontSize='sm' color={'#393E46'}>{games_count + ' ' + game_name +' game'}{ games_count > 1? 's.' : '.'}  </Text> 
        <Rating value={new_rating} progress={rating_change}/>
        </HStack>
        </Center>
        )
        out_played_games_info.push()
        i+=1
      }
    }else{
      url = 'https://api.noteefy.net/static/playing_v3.png'
      text = 'is playing'
    }
   
    
  }
  return(
    <Box
    p={2}
    backgroundColor={'brand.badgePerformanceElement'}
      borderRadius={10}
    // p={4} // distance from the border - NOT MARGIN - margin is outside the box
    // borderRadius={10}
    boxShadow="md"
    >
      <VStack align={'left'}> 
      <HStack>
      <Text width={'20%'} fontSize='sm' color={'#393E46'}>{timestamp_to_datetime(timestamp*1000)}</Text>
        <Center borderRadius={7} backgroundColor={'brand.badgeBackground'}width={40} height={7}>
          <Text fontSize='sm' color={'#393E46'}>{player}</Text>  
          </Center>
          <Tooltip label={text}>
          <Image src={url} boxSize={5}/>
          </Tooltip>
          {/* <Text fontSize='sm'color={'#393E46'}>{text}</Text> */}
          <Link href={tv_link} isExternal>
          <Button onClick={(e) => e.stopPropagation()} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>{'TV'}</Button>
        </Link>
        <Link href={profile_link} isExternal>
          <Button onClick={(e) => e.stopPropagation()} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>
          <Image src={'https://api.noteefy.net/static/Profile_picture.png'} boxSize={4}/>
          </Button>
        </Link>
          <Spacer/>
      </HStack>
      {out_played_games_info}
      </VStack>
    </Box>

)
}


function Rows({data}){

  let out = []
    if(data)
    {
      for(const event_data of data){
        out.push( 
        <Event 
        data_string={event_data}
        />)
      }
    }
    return (
      
      <VStack>
        {out}
      </VStack>  

      )
  }


function  PlayersTable({data, table_height}){
  const [filterString, setFilterString] = useState('')
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    let tempData = [];
    for (const single_event of data) {
      if (
        (single_event.toLowerCase().includes(filterString.toLowerCase()) || filterString == '')
      ) {
        tempData.push(single_event);
      }
    }
    setFilteredData(tempData);
  }, [data, filterString]);
  
const filterStringChanged = useCallback(async (event) => {
  let currentFilterString = filterString;
  if (event != undefined) {
    currentFilterString = event.target.value;
    setFilterString(currentFilterString);
  }

  let tempData = [];
  for (const single_event of data) {
    if (
      (single_event.toLowerCase().includes(currentFilterString.toLowerCase()) || currentFilterString == '') &&
      tempData.length < 100
    ) {
      tempData.push(single_event);
    }
  }
  setFilteredData(tempData);
}, [data, filterString]);
  

  return(
    <VStack>
        <HStack width={'100%'}>
           <Text>
              Highlighted players updates:
          </Text>
          <Spacer/>
          <InputGroup width={'50%'}>
          <Input   placeholder='filter players' onChange={filterStringChanged} backgroundColor={'brand.lightBackground'}>
          </Input>
        </InputGroup>
        </HStack>
        <Box overflowY="auto" height={table_height - 100} width={400}>
          
          <Rows data={filteredData} table_height={table_height}/>                      
        </Box>
      </VStack>
  )

}

function readChunks(reader) {
  return {
      async* [Symbol.asyncIterator]() {
          let readResult = await reader.read();
          while (!readResult.done) {
              yield readResult.value;
              readResult = await reader.read();
          }
      },
  };
}



export default function EventsTable({table_height}) {
  const [outEvents, setOutEvents] = useState([])

  

  const handleGetEvents = async() =>{
    const url = 'https://api.noteefy.net/events/'
    fetch(url).then(async (response) => {
      const reader = response.body.getReader();
      for await (const chunk of readChunks(reader)) {          
          var text = new TextDecoder().decode(chunk);
          let splitted = text.split('\r\n')
          splitted.pop()
          
          for(const element of splitted){
            setOutEvents((previousEvents) => [element, ...previousEvents])
          }
          
      }
    }) 
  }

  useEffect(()=>{
    handleGetEvents()
  }, [])

  
  return (
    <Box borderRadius={20} p={3} borderWidth={1} height={table_height}>
    <PlayersTable data={outEvents} table_height={table_height}/>
    </Box>
  )}