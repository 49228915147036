import { useState, useCallback } from "react";

import {
  Input,
  Button,
  InputGroup,
  Stack,
  Box,
  Link,
  InputRightElement,
  useToast,
  Center,
  Heading,
  Text,
  VStack,
  Flex,
  SimpleGrid
} from "@chakra-ui/react";
import axios from 'axios';
import ConfirmDeleteAccountModal from "./ConfirmDeleteAccountModal";
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}


const LoginApp = (params) => {
  const [loginValue, setLoginValue] = useState('');
  const [newUserLoginValue, setNewUserLoginValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [newUserPassValue, setNewUserPassValue] = useState('');
  
  const toast = useToast();

  const notify_callback = useCallback((result, body, notify_error=true, verified=true) => {
    if (result) {
      if (verified)
      {
        toast({
          title: 'Login success',
          description: body,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      }else{
        toast({
          title: 'Login error',
          description: 'Your account was not verified. Please check your e-mail.',
          status: 'info',
          duration: 5000,
          isClosable: true
        })
      }
      ;
    }
    else{
      if(notify_error){
        if (verified)
        {
        toast({
          title: 'Login error',
          description: 'Please check login or password.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

    return
  }}});

  
  const turn_off_notifications_callback = useCallback(() => {

    toast({
      title: 'Notifications have been turned off',
      description: 'All mail notifications have been turned off',
      status: 'info',
      duration: 3000,
      isClosable: true
    })

return
});


  const [show, setShow] = useState(false)
  const [showNewUser, setShowNewUser] = useState(false)

  const handleClick = () => setShow(!show)
  const handleClickNewUser = () => setShowNewUser(!showNewUser)

  const handleLoginChange = (new_val) => setLoginValue(new_val.target.value);
  const handlePassChange = (new_val) => setPassValue(new_val.target.value);

  const handleNewUserPassChange = (new_val) => setNewUserPassValue(new_val.target.value);
  const handleNewUserLoginChange = (new_val) => setNewUserLoginValue(new_val.target.value);

  const logOut = async () =>{
    params.handleLogin(false)
    params.setLoggedIn(false)
    params.setUserLogin('')
    params.setAdminValue(false)
    params.removeCookies('sessionId')
    params.cookies.setCookies('sessionId', '', { expires: new Date(0), path: '/' })
    params.cookiesLogin()
  }

  function HorizontalBadgeContainer(text, value){
    return (
    <Box p={2} borderRadius={10} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
      <VStack>
      <Text fontSize='s'>
        {text}:
      </Text>
      <Text fontSize='s' as='b'>
        {value}
      </Text>
      </VStack>
    </Box>)
  }

  function button_container(text, link){
    return (
      <Box borderRadius = {7} p={2} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
        <Link href={link} width={'100%'}>
          <Center height={'100%'}>
            <Button width={'80%'} onClick={(e) => e.stopPropagation()} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>{text}</Button>
          </Center>
        </Link>
      </Box>)
  }

  function button_func_container(text, func){
    return (
      <Box borderRadius = {7} p={4} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
        <Center>
          <Button width={'80%'}onClick={func} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>{text}</Button>
        </Center>
      </Box>)
  }

  async function turn_off_notifications(){
    let req = axios.post('https://api.noteefy.net/notifications_off/', [params.cookies['sessionId']])
    turn_off_notifications_callback()
  }

  function timestamp_to_datetime(seen_val){
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if(seen_val == 0){
      return '-'
    }
    return new Date(seen_val).toLocaleDateString('en-GB', options) + ' ' + new Date(seen_val).toLocaleTimeString('en-GB')
  }

  
  const handleLoginButton = async (event) => {
    let req = await axios.post('https://api.noteefy.net/login/', [loginValue, passValue], )
    
    event.preventDefault()
    params.handleLogin(req.data[0])
    params.setLoggedIn(req.data[0])
    params.setUserLogin(req.data[1])
    setLoginValue(req.data[1])
    params.setAdminValue(req.data[2])
    const hundredDaysFromNow = new Date();
    hundredDaysFromNow.setDate(hundredDaysFromNow.getDate() + 100);
    params.setCookies('sessionId', req.data[3], { expires: hundredDaysFromNow, path: '/' })
    // params.setCookies('sessionId', )
    params.setUserData(req.data[5])
  
    notify_callback( req.data[0], req.data[1], "You are logged in as: " + req.data[1], true, req.data[4])

  }

  const handleSingIn = async () => {
    if(newUserLoginValue.includes('@') && newUserLoginValue.includes('.')){
      if(newUserPassValue.length > 5){
        await axios.post('https://api.noteefy.net/sign/', [newUserLoginValue, newUserPassValue])
      toast({
        title: 'Created account',
        description: 'check your e-mail and verify your Noteefy account.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      }
      else(
        toast({
          title: 'Password too short',
          description: 'Please write a password that is at least 6 letters long.',
          status: 'info',
          duration: 5000,
          isClosable: true
        })
      )
    }
    else{
      toast({
        title: 'Wrong e-mail address',
        description: 'Please write a valid e-mail address.',
        status: 'info',
        duration: 5000,
        isClosable: true
      })
    }
    
  }

  return (

  params.isLoggedIn?

<>
  <Center
  width={'100%'}
  height={'100%'}
  >
    <Flex 
      width={params.isMobile? '90%': '50%'}
      p={5}
      borderRadius={10}
      backgroundColor={'brand.lightBackground'}>
      <VStack width={'100%'}>

      <br/>
      <Text>
        Your account settings and statistics
      </Text>
      <br/>
      <SimpleGrid columns={params.isMobile? 1 : 2} spacing={5} width={'90%'}>
      {HorizontalBadgeContainer('E-mail address', params.userData['username'])}
      {HorizontalBadgeContainer('Joined date', new Date(params.userData['created_timestamp']).toLocaleDateString('en-GB'))}
      {HorizontalBadgeContainer('Watched Lichess players', Object.keys(params.userData['Lichess_players_list']).length)}
      {HorizontalBadgeContainer('Watched Chess.com players', Object.keys(params.userData['Chesscom_players_list']).length)}
      {HorizontalBadgeContainer('Last notification sent', timestamp_to_datetime(params.userData['last_notification']['timestamp']))}
      {HorizontalBadgeContainer('Total notifications sent', params.userData['notifications_count'])}
      {button_container('Reset password', '/forgotpassword/')}
      <ConfirmDeleteAccountModal sessionId={params.cookies['sessionId']} logOut={logOut}/>
      {button_func_container('Turn off all notifications', turn_off_notifications)}
      {button_func_container('Log out', logOut)}
      </SimpleGrid >
      </VStack>
    </Flex>
  </Center>

    
  </>
  : <>

  <Center height={800}>
      <VStack>

  <Stack
    flexDir="column"
    mb="2"
    justifyContent="center"
    alignItems="center"
  >
    <Heading size='lg'> Welcome back!</Heading>
    <Box minW={{ base: "70%", md: "468px" }}>
        <Stack
          spacing={4}
          p="1rem"
          backgroundColor="brand.badgeBackground"
          boxShadow="md"
        >
          <form
          onSubmit={e=> {
            e.preventDefault()
            handleLoginButton(e)
          }}>
              <Input type="email" 
              placeholder="e-mail address" 
              onChange={handleLoginChange}/>
              <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='Enter password'
                onChange={handlePassChange}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button
            borderRadius={8}
            variant="solid"
            backgroundColor='#F2E7D5'
            width="full"
            type="submit"
          >
            Login
          </Button>
          </form>
          <Link color="teal.500" href={"/forgotpassword/" + loginValue} onClick={handleSingIn}>
      Forgot password?
    </Link>
        </Stack>
    </Box>
  </Stack>




  <Stack
    flexDir="column"
    mb="2"
    justifyContent="center"
    alignItems="center"
  >
    <Heading size='lg'> Want to join Noteefy?</Heading>
    <Box minW={{ base: "70%", md: "468px" }}>
        <Stack
          spacing={4}
          p="1rem"
          backgroundColor="brand.badgeBackground"
          boxShadow="md"
        >
            <form
          onSubmit={e=> {
            e.preventDefault()
            handleSingIn(e)
          }}>
              <Input type="email" 
              placeholder="e-mail address" 
              onChange={handleNewUserLoginChange}/>
              <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type={showNewUser ? 'text' : 'password'}
                placeholder='Enter password'
                onChange={handleNewUserPassChange}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClickNewUser}>
                  {showNewUser ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button
            // onClick={handleSingIn}
            borderRadius={8}
            variant="solid"
            backgroundColor='#F2E7D5'
            width="full"
            type="submit"
          >
            Sign up
          </Button>
          </form>
        </Stack>
    </Box>
  </Stack>
  </VStack>

  </Center>


</>
  
  );
};

export default LoginApp;
