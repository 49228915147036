// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



import React, { useCallback } from 'react'
import { Navigate } from 'react-router-dom';
import { useState, useRef, useEffect} from "react";
import { useCookies } from 'react-cookie'
import axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as RouteLink
} from "react-router-dom";
import { ChakraProvider, extendTheme, Text, Box, Button,
  Grid,
  VStack,
  GridItem,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  useToast,
useDisclosure,
Spacer,
HStack,
} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import { MdHome, MdNotificationsNone, MdAccountCircle, MdInfoOutline, MdFlashOn, MdPeople } from 'react-icons/md'
import { HamburgerIcon } from '@chakra-ui/icons'

import LichessTab from "./lichessTab"
import ChesscomTab from "./chesscomTab"
import PlayerBadgeShower from "./PlayerBadgeShower"
import VerifyPage from "./routes/VerifyPage/VerifyPage"
import LoginApp from "./login.js"
import AboutPage from "./routes/AboutPage/AboutPage"
import QuickStart from "./routes/QuickStart/QuickStart"
import HomePage from "./routes/HomePage/HomePage"
import ForgotPasswordPage from "./routes/ForgotPasswordPage/ForgotPasswordPage"
import NewPasswordPage from "./routes/NewPasswordPage/NewPasswordPage"
import EventsTable from "./EventsTable.js"

const firebaseConfig = {
  apiKey: "AIzaSyA0xNKFYnpDhzm8_Nh-ep4xpA57aO_AbdY",
  authDomain: "noteefy-c7b6c.firebaseapp.com",
  projectId: "noteefy-c7b6c",
  storageBucket: "noteefy-c7b6c.appspot.com",
  messagingSenderId: "51779599937",
  appId: "1:51779599937:web:748c81ac3e9c9f731590c0",
  measurementId: "G-LVSTTNR5LW"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function DrawerNavbar(){
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  return(
  <VStack height={'100%'}>
  <Button height={'100%'} ref={btnRef} backgroundColor='brand.badgePerformanceElement' onClick={onOpen}>
  <HamburgerIcon/>
  </Button>
  <Drawer
    isOpen={isOpen}
    placement='right'
    onClose={onClose}
    finalFocusRef={btnRef}
  >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      {/* <DrawerHeader></DrawerHeader> */}

      <DrawerBody p={2}>
        <VStack p={2}>
          <NavBarLinkButton text={'Home'} link={'/home'} onClick_func={onClose} icon={MdHome}/>
          <NavBarLinkButton text={'Quick start'} link={'/quickstart'} onClick_func={onClose} icon={MdFlashOn}/>
          <NavBarLinkButton text={'Status updates'} link={'/updates'} onClick_func={onClose} icon={MdNotificationsNone}/>
          <NavBarLinkButton text={'Lichess players'} link={'/lichess'} onClick_func={onClose} icon={MdPeople}/>
          <NavBarLinkButton text={'Chess.com players'} link={'/chesscom'} onClick_func={onClose} icon={MdPeople}/>
          <NavBarLinkButton text={'Account'} link={'/account'} onClick_func={onClose} icon={MdAccountCircle}/>
          <NavBarLinkButton text={'About'} link={'/about'} onClick_func={onClose} icon={MdInfoOutline }/>
        </VStack>
      </DrawerBody>

      <DrawerFooter>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
</VStack>)

}
function NavBar ({events_table_height, button_height}){
    return(
      <VStack height={'100%'}>
      <NavBarLinkButton text={'Home'} link={'/home'} button_height={button_height} icon={MdHome}/>
      <NavBarLinkButton text={'Quick start'} link={'/quickstart'} button_height={button_height} icon={MdFlashOn}/>
      <NavBarLinkButton text={'Lichess'} link={'/lichess'} button_height={button_height} icon={MdPeople}/>
      <NavBarLinkButton text={'Chess.com'} link={'/chesscom'} button_height={button_height} icon={MdPeople}/>
      <NavBarLinkButton text={'Account'} link={'/account'} button_height={button_height} icon={MdAccountCircle}/>
      <NavBarLinkButton text={'About'} link={'/about'} button_height={button_height} icon={MdInfoOutline}/>
      <Spacer></Spacer>
      <EventsTable table_height={events_table_height}/>
  </VStack>
  )
}

const NavLink = ({ text, icon}) => (
  <Link>
    <HStack align="center">
      {/* <Icon as={icon} borderWidth={1} borderColor={'black'}/> */}
      <Icon as={icon}/>
      <Text fontSize="xl">{text}</Text>
    </HStack>
  </Link>
);

function LinkButton({text, link, icon=null}){
  return(<Button backgroundColor={'brand.badgePerformanceElement'}>
        <RouteLink to={link}>
          <NavLink text={text} icon={icon}/>
        </RouteLink>
        </Button>)
}

function NavBarLinkButton({text, link, onClick_func, button_height, icon}){
  return(
    <RouteLink to={link}>
        <Button backgroundColor={'brand.badgePerformanceElement'} shadow={'md'} width={200} height={button_height } onClick={onClick_func}>
          <NavLink text={text} icon={icon}/>    
        </Button>
    </RouteLink>)
}

function MainPageHeader({height, isMobile}){

  return(
    <HStack height={height} backgroundColor='#393E46' width={'100%'}>
      <LinkButton text='Noteefy' link={'/home'} icon={MdHome}/>
      <Spacer/>
      {isMobile? <DrawerNavbar/> : <LinkButton text='Account' link={'/account'} icon={MdAccountCircle}/>}
    </HStack>
  )
}



function NoteefyApp(){
  const windowWidth = useRef(window.innerWidth);
  const window_width_temp = useRef(window.innerWidth).current;
  const windowHeight = useRef(window.innerHeight);
  const header_height = 45
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const hundredDaysFromNow = new Date();
  hundredDaysFromNow.setDate(hundredDaysFromNow.getDate() + 100);

  const [cookies, setCookies, removeCookies] = useCookies();
  const [isMobile, setIsMobile] = useState(true)

  const [userLogin, setUserLogin] = useState('');
  const [adminValue, setAdminValue] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [userData, setUserData] = useState();

  // const getPreview = async function(){
  //   let req = await axios.get('https://api.noteefy.net/badgepreview/')
    
  //   setPreviewData(req.data)
  // }

  const toast = useToast();

  const notify_callback = useCallback((result, body, notify_error=false, verified=true) => {
    if (result) {
      toast({
        title: 'Logged in',
        description: body,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    }
    else{
      if(notify_error){
        if (!verified)
        {
          toast({
            title: 'Login error',
            description: 'Your account was not verified. Please check your email.',
            status: 'info',
            duration: 5000,
            isClosable: true
          })

        }
        else 
        {
        toast({
          title: 'Login error',
          description: 'Please check login or password.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }

    return
  }}});

  const cookiesLogin = async ()=>{
    let req = await axios.post('https://api.noteefy.net/login/', ['', '', cookies['sessionId']] )
    let login_value = req.data[1]
    let cookie = req.data[3]
    setCookies('sessionId', cookie, { expires: hundredDaysFromNow, path: '/' })
    // cookies.sessionId = cookie


    if(cookie != login_value){
      setLoggedIn(req.data[0])
      notify_callback( req.data[0], "You are logged in as: " + req.data[1], false, req.data[3])
    }
    
    setUserLogin(login_value)
    setAdminValue(req.data[2])
    setUserData(req.data[5])

    
  }

  useEffect(() => {
    cookiesLogin()
 }, [])

  useEffect(()=>{
    if(windowHeight.current < windowWidth.current){
      setIsMobile(false)
    }
    else {setIsMobile(true)}
  }, [windowSize])

  const [loggedIn, setLoggedIn] = useState(false);

  function handleLogin(value){
    setLoggedIn(value)}

  const window_size = {
    window_height: windowHeight.current,
    window_width: windowWidth.current,
    header_height: 100
  }
  let button_height = 9
  let events_table_height = (windowHeight.current - header_height - 200) * 0.8
  events_table_height = events_table_height < 400 ? 400 : events_table_height
    const theme = extendTheme({
      colors:{
        brand: {
          beige: '#F4DFBA',
          yellow: '#EEC373',
          lightBrown: '#CA965C',
          darkBrown: '#876445',
          highlight: '#876445',
          badgeBackground: '#fbf9f6',
          lightBackground: '#fbf9f6',
          lightSmallElement: '#fffefe',
          badgePerformanceElement: '#efe7d7',
          }
              },
      sizes:{
      }
  })
  
  return(<ChakraProvider theme={theme}>
{
        <Router>
          <VStack>
            {showHeader?
            <MainPageHeader height={header_height} isMobile={isMobile}/>
            : null
            }
          
        
        {isMobile?
        <Routes>

          <Route exact path="/topsecretplace/:site/:username" element={
          <PlayerBadgeShower isMobile={isMobile} site={'Lichess'} username={'frodulec'} setShowHeader={setShowHeader}/>}>
          </Route>

          <Route exact path="/" element={<HomePage isMobile={isMobile}/>}>
          </Route>

          <Route exact path="/home" element={<HomePage isMobile={isMobile}/>}>
          </Route>

          <Route exact path="/chesscom" element={
            <ChesscomTab 
            window_width = {window_width_temp} 
            height={windowHeight.current - header_height} 
            isMobile={isMobile} 
            userLogin={userLogin} 
            isLoggedIn={loggedIn} 
            cookies={cookies} 
            setCookies={setCookies}/>}>
          </Route>

          <Route exact path="/lichess" element={
            <LichessTab
              window_width = {window_width_temp} 
              height={windowHeight.current - header_height} 
              isMobile={isMobile} 
              userLogin={userLogin} 
              isLoggedIn={loggedIn} 
              cookies={cookies} 
              setCookies={setCookies}/>}>
          </Route>
          
          <Route exact path="/account" element={<LoginApp
                                                  isMobile={isMobile}
                                                  isLoggedIn={loggedIn}
                                                  setLoggedIn={setLoggedIn}
                                                  cookiesLogin={cookiesLogin}
                                                  handleLogin={handleLogin}
                                                  setUserLogin={setUserLogin}
                                                  cookies={cookies}
                                                  setCookies={setCookies}
                                                  removeCookies={removeCookies}
                                                  setAdminValue={setAdminValue} 
                                                  userData={userData}
                                                  setUserData={setUserData} 
                                                  />}>
          </Route>
          <Route exact path="/about" element={<AboutPage isMobile={isMobile}/>}>
          </Route>
          <Route exact path="/quickstart" element={<QuickStart isMobile={isMobile}/>}>
          </Route>
          <Route exact path="/verification/:success" element={<VerifyPage/>}>
          </Route>
          <Route exact path="/forgotpassword/:email" element={<ForgotPasswordPage/>}>
          </Route>
          <Route exact path="/forgotpassword/" element={<ForgotPasswordPage/>}>
          </Route>
          <Route exact path="/newpass/:verificationtext" element={<NewPasswordPage/>}>
          </Route>
          <Route exact path="/updates" element={<EventsTable table_height={0.95*(windowHeight.current - header_height)}/>}>
          </Route>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        :
        <Grid
        h={'100%'}
        templateRows='repeat(2, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
      >
        <GridItem rowSpan={2} colSpan={1}>
        <Box p={5} backgroundColor={'brand.lightBackground'} height={windowHeight.current - header_height*2}>
        <NavBar events_table_height={events_table_height} button_height = {button_height}/>
        </Box>
        </GridItem>

        <GridItem colSpan={4} rowSpan={2} >
          {/* central area */}
        <Routes>
        <Route exact path="/" element={<HomePage isMobile={isMobile}/>}>
          </Route>

          <Route exact path="/home" element={<HomePage isMobile={isMobile}/>}>
          </Route>

          <Route exact path="/chesscom" 
            element={
            <ChesscomTab
              window_width = {window_width_temp}
              height={windowHeight.current - header_height} 
              isMobile={isMobile} 
              userLogin={userLogin} 
              isLoggedIn={loggedIn} 
              cookies={cookies} 
              setCookies={setCookies}/>}>
          </Route>

          <Route exact path="/lichess" 
            element={
              <LichessTab
                window_width = {window_width_temp}
                height={windowHeight.current - header_height} 
                isMobile={isMobile} 
                userLogin={userLogin} 
                isLoggedIn={loggedIn} 
                cookies={cookies} 
                setCookies={setCookies}/>}>
          </Route>

          <Route exact path="/account" element={<LoginApp
                                                  isMobile={isMobile}
                                                  isLoggedIn={loggedIn}
                                                  setLoggedIn={setLoggedIn}
                                                  cookiesLogin={cookiesLogin}
                                                  handleLogin={handleLogin}
                                                  setUserLogin={setUserLogin}
                                                  cookies={cookies}
                                                  setCookies={setCookies}
                                                  removeCookies={removeCookies}
                                                  setAdminValue={setAdminValue} 
                                                  userData={userData}
                                                  setUserData={setUserData} 
                                                  />}>
          </Route>
          <Route exact path="/about" element={<AboutPage/>}>
          </Route>
          <Route exact path="/quickstart" element={<QuickStart/>}>
          </Route>
          <Route exact path="/verification/:success" element={<VerifyPage/>}>
          </Route>
          <Route exact path="/forgotpassword/:email" element={<ForgotPasswordPage/>}>
          </Route>
          <Route exact path="/forgotpassword/" element={<ForgotPasswordPage/>}>
          </Route>
          <Route exact path="/newpass/:verificationtext" element={<NewPasswordPage/>}>
          </Route>
        </Routes>
      
        </GridItem>
      </Grid>}
      </VStack>
      </Router>
}

  </ChakraProvider>
  )

}
  

export default NoteefyApp
