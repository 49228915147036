import React, { useState, useCallback, useEffect } from 'react'
import { Link } from '@chakra-ui/react'
import { Box, Image, Text, Td, Tr, Th, TableCaption, TableContainer, Table, Thead, Tbody, Button, Center, VStack, HStack} from '@chakra-ui/react';
import axios from 'axios';

import SettingsModal from "./SettingsModal"

function BoldNumber({text}){
  return(
    <Text as='b'>{text}</Text>
  )
}

function Rating({value, progress, fontSize}){
  let progress_color = 'grey'
  let prefix = '+'
  if (progress>0){
    progress_color = 'green'
  } else if (progress < 0){
    progress_color = 'red'
    prefix = ''
  }
  return(
    <VStack spacing={0}>
    <Text as='b' fontSize={fontSize}>{value}</Text>
    <Text as='b' fontSize={fontSize} color={progress_color}>{prefix + String(progress)}</Text>
    </VStack>
  )
}

export default function GameBadge(game_name, icon_url, game_data, game_data_name, game_badge_width, fontSize) {
  let progress = 0
  let rating = 0
  if(game_data_name in game_data){
    progress = game_data[game_data_name]['prog']
    rating = game_data[game_data_name]['rating']
  }

  return (
              <Box //box with the badge
                  p={2} // distance from the border - NOT MARGIN - margin is outside the box
                  borderRadius={10}
                  width={game_badge_width}
                  boxShadow="md"
                  backgroundColor={'brand.badgePerformanceElement'}
              >
              <VStack>
                <Text fontSize={fontSize} >{game_name}</Text>
                <Rating value={rating} progress={progress} fontSize={fontSize}/>
                <Image src={icon_url}/>
              </VStack>
              </Box>

  )
}

