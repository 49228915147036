import React from 'react'
import {useCallback, useEffect, useState, useRef} from "react";
import {Box, Button, Text, useToast, HStack, Table, Heading, Tbody, Tr, Td, Input, InputGroup, Tab,
  TabList, TabPanels, TabPanel, Tabs, VStack, Center, Spacer, SimpleGrid
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'
import { MdPeople } from 'react-icons/md'
import axios from 'axios';
import PlayerBadge from "./PlayerBadge"
import { useCookies } from 'react-cookie'
import { ButtonTextWithIcon } from './commons';


function SingleRow(params){
  const toast = useToast();

  const add_player = async function(){
    let req = axios.post('https://api.noteefy.net/addplayer/', [params.username, params.cookies, 'Lichess'] )
    if((await req).data != null){
      params.add_dummy_badge((await req).data)
    }
    else{
      toast({
        title: 'Could not add this player.',
        description: 'The account was deleted.',
        status: 'info',
        duration: 3000,
        isClosable: true
      })
    }
  }
  return(
    <Tr>
      <Td> {params.username}</Td>
      <Td><Button backgroundColor='#F2E7D5' onClick={add_player}> Add</Button></Td>
    </Tr>
)
}


function Rows(params){
  let out = []
    if(params.data)
    {
      for(const val of params.data){
        out.push( <SingleRow username={val}
          add_dummy_badge={params.add_dummy_badge}
          cookies={params.sessionCookie} 
          selectedItems={params.selectedItems} setSelectedItems={params.setSelectedItems}/>)
      }
    }
    return (out)
  }


function filterData(data_to_filter, filter_string){
  if(data_to_filter.length)
  {
    const result = data_to_filter.filter(singlePair => singlePair[0].toLowerCase().includes(filter_string.toLowerCase()));
    return result
  }
}


function  PlayersTable(params){
  const [reference_data, set_reference_data] = useState()
  const [filterString, setFilterString] = useState('')      
  const [data, setData] = useState()

  const filterPlayersCallback = useCallback( async()=>{
    if(filterString.length > 3){
      const new_data = filterData(reference_data, filterString)
    setData(new_data)
    }
      
  })

  const filterPlayersWithRequestCallback = useCallback( async()=>{

    let req = await axios.get(params.url, {
      params: {
        filterstring: filterString,
        streamers: true,
        leaderboards: true,
        regular: true,
        site: 'Lichess'
      }
    })
    set_reference_data(req.data)
    setData(req.data)
    return(req.data)
  })

  function AddPlayerFromString(){
    const toast = useToast();
    const add_player = async function(){  
      let req = axios.post('https://api.noteefy.net/addplayer/', [filterString, params.sessionCookie, 'Lichess'] )
      if((await req).data != null){
        params.add_dummy_badge((await req).data)
      }
      else{
        toast({
          title: "Couldn't find this player.",
          description: "We couldn't find " + filterString + '.\nMaybe try different spelling.',
          status: 'info',
          duration: 3000,
          isClosable: true
        })
      }
    }

    if(filterString.length > 3){
      return(
          <Button backgroundColor='#F2E7D5' onClick={add_player}> Add</Button>
      )
    }
  }


  const filterStringChanged = useCallback( async(event) => {
    setFilterString(event.target.value)
  })

  useEffect(() => {
    
      const timeOutId = setTimeout(() => {
        if (params.filter_with_request){
          filterPlayersWithRequestCallback()
        }
        else{
          filterPlayersCallback()
        }
        
    }, 200);    
    return () => clearTimeout(timeOutId);
  }, [filterString]);

  return(
    <VStack width={'100%'}>  // box with the list + filter box
      <HStack width={'100%'}>
        <InputGroup width={'40%'}> 
          <Input placeholder='filter players' onChange={filterStringChanged} 
          backgroundColor={'brand.lightBackground'}>
          </Input>
        </InputGroup>
        <AddPlayerFromString username={filterString}/>
      </HStack>
    <Box width={'100%'} height={params.players_table_height} overflow={"scroll"}> 
      <Heading size='lg'>{params.title}</Heading>
        <Table variant="striped">
          </Table>
        <Box overflowY="auto"  >
          <Table>
            <Tbody>
              <Rows add_dummy_badge={params.add_dummy_badge} 
              data ={data} 
              sessionCookie={params.sessionCookie} 
              selectedItems={params.selectedItems} 
              setSelectedItems={params.setSelectedItems}
              />                      
            </Tbody>
          </Table>
        </Box>
      </Box>
    </VStack>
  )
}



export default function LichessTab(params) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [cookies, setCookies, removeCookies] = useCookies('sessionCookie');
  const [badgesItems, setBadgesItems] = useState({});
  const [outBadges, setOutBadges] = useState([])
  const [testNumber, setTestNumber] = useState(4)
  const [tabIndex, setTabIndex] = useState(0)
  const [loadedPlayers, setLoadedPlayers] = useState(false)
  const p_val = 5
  const title_box_height = 20
  const header_height = 20
  const inner_grid_height = params.height - title_box_height - header_height - p_val * 4 //?
  
  useEffect(()=>{
    if(params.userLogin.length > 0)
    {
      handleUpdateSelectedList()
    }

  }, [params.isLoggedIn, params.userLogin])

useEffect(()=>{
    const localBadges = []
    Object.keys(badgesItems).forEach(function(key, index) {
      let username = 'NoUsername'
      const seen_at = badgesItems[key]['seenAt']
      const online = badgesItems[key]['online']
      const playing = badgesItems[key]['playing']
      if('username' in badgesItems[key]){
        username = badgesItems[key]['username']
      }
      localBadges.push({
        seen_at,
        online,
        playing,
        badge: <PlayerBadge
          window_width = {params.window_width}
          tv_link = {'http://lichess.org/@/' + username.toLowerCase() + '/tv'}
          profile_link = {'http://lichess.org/@/' + username.toLowerCase()}
          cookies={cookies['sessionId']}
          chess_site='Lichess'
          isMobile ={params.isMobile}
          showBadge ={false}
          handleUpdateBadges = {handleUpdateBadges}
          badges={badgesItems}

          nick={username} 
          data={badgesItems[key]}
        />
      })
    })

    localBadges.sort((a, b) => {
      if (a.playing && !b.playing) return -1;
      if (!a.playing && b.playing) return 1;
      if (a.online && !b.online) return -1;
      if (!a.online && b.online) return 1;
      return new Date(b.seen_at) - new Date(a.seen_at);
    })

    handleSetOutBadges(localBadges.map(item => item.badge))
}, [JSON.stringify(badgesItems)])


  const handleUpdateSelectedList = async() =>{
    let req = await axios.get('https://api.noteefy.net/loadsettings/', {
      params: {
        userLogin: params.userLogin,
        chess_site: 'Lichess'
      }
    })
    handleUpdateBadges(await req.data)
  }
  
  
  const handleUpdateBadges = (users) =>{
   setBadgesItems(users)
   setLoadedPlayers(true)
   setTestNumber(testNumber + 1)
  }

  const handleSetOutBadges = (users) =>{
   setOutBadges(users)
   if(loadedPlayers){
    sendPlayers()
   }
  }


  const sendPlayers = () => {
    let req = new XMLHttpRequest()
    req.open('POST', 'https://api.noteefy.net/savesettings/')
    const outlist = []
    for(let item in badgesItems){
      let player_data = [item, badgesItems[item].settings] 
      outlist.push(player_data)
    }

    req.send(JSON.stringify([params.userLogin, outlist, 'Lichess']))
  }
  const add_dummy_badge = function(data){
      let abc = badgesItems
      abc[data['username']] = data
    setBadgesItems(abc)
    setTestNumber(testNumber + 1)
  }

  const NoBadges = function(){
    const global_width = params.isMobile? params.window_width/1.1 : 550
  const badge_width = params.isMobile? global_width / 1.1 : global_width
  const hw_ratio = 1.3
  const global_height = hw_ratio * global_width
  const fontSize  = global_height/45
  const column_width = global_width/2.4
  const elements_spacing = 2
  const small_elements_border_radius = global_width/70
    return(
      <Box //box with the badge
      p={elements_spacing} // distance from the border - NOT MARGIN - margin is outside the box
      borderRadius={small_elements_border_radius}
      boxShadow="md"
      width={badge_width}
      backgroundColor={'brand.badgeBackground'}
    >
      <HStack>
      <Text>
        Your players list is currently empty. Select players from the  
        {params.isMobile?<Button backgroundColor='#F2E7D5' onClick={e => handleTabsChange(1)}>
        
        <ButtonTextWithIcon icon={SearchIcon} text='Add players'/>
        </Button > : ' search box'
        }
      </Text>
      </HStack>
  
  </Box>
    )
  }


  const SelectedPlayers = function({height}){
    // add ref to the box with the list of players
    const [players_list_description_height, set_players_list_description_height] = useState(0)

    let width = '90%'
    if(params.isMobile){
      width = '100%'
    } 
    return(
      <VStack width={'100%'}height={height- 50 - players_list_description_height}>
        <LichessPlayersTextBox set_height={set_players_list_description_height}/>
      
    <Box height={height} width={width} overflowY="auto">
      
      <VStack columns={1} spacingX='20px' spacingY='20px'>
        <Spacer/>
        {outBadges.length > 0 ? outBadges : <NoBadges></NoBadges>}
        <Spacer/>
      </VStack>
    </Box>
    </VStack>
    )}



const SearchList = function(){
  // box with the list + filter box + description
  const description_box_ref = useRef(null)
  const [playersTableHeight, setPlayersTableHeight] = useState(0);
  useEffect(() => {
    if (description_box_ref.current) {
      setPlayersTableHeight(inner_grid_height - description_box_ref.current.clientHeight - 100);
    }
  }, [description_box_ref.current, inner_grid_height]);
  let width = '90%'
    if(params.isMobile){
      width = '100%'
    }
    return(
      <VStack  width={width}>
      <Box p={3} 
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md"
        ref={description_box_ref}>
        
        <Text>
          Select players from the table below. To select a specified player, start typing in the filter box below. 
          If no player with the username is shown in the list, but you are sure that such Lichess account exists, 
          you can click 'Add' near the filter box to try adding player with this exact username.
        </Text>
      </Box>
    <PlayersTable 
      is_mobile={params.isMobile}
      add_dummy_badge={add_dummy_badge} 
      title=''
      url='https://api.noteefy.net/players' 
      filter_with_request={true} 
      sessionCookie={cookies['sessionId']} 
      selectedItems={selectedItems} 
      setSelectedItems={setSelectedItems}
      players_table_height={playersTableHeight}
    />
    </VStack>
    )
}

const LichessPlayersTextBox = function({width, set_height}){
  const description_box_ref = useRef(null)
  useEffect(() => {
    if (description_box_ref.current) {
      set_height(description_box_ref.current.offsetHeight);
    }
  }, []);
  return(
  <Box p={3}
  ref={description_box_ref} 
  width={width}
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md">
        <Text>
          To get notifications regarding a Lichess player, add players from the search box. 
          To choose what kind of notifications are you intersted in, go to a players detailed settings and tick or untick accordingly.
        </Text>
 </Box>
 )
}

const handleTabsChange = (index) => {
  setTabIndex(index)
}
  
  return (

<>
{params.isMobile?
<Tabs variant='soft-rounded'
  colorScheme='gray' 
  width={'100%'} 
  index={tabIndex} 
  onChange={handleTabsChange}>
<Center>
<TabList width={'100%'} >
  <Tab borderRadius={8} width={'40%'} backgroundColor={'brand.badgePerformanceElement'} ><ButtonTextWithIcon icon={MdPeople} text={'Your list'}/></Tab>
  <Spacer/>
  <Tab borderRadius={8} width={'40%'}backgroundColor={'brand.badgePerformanceElement'}><ButtonTextWithIcon icon={SearchIcon} text='Add players'/></Tab>
</TabList>
</Center>
  <TabPanels>
    <TabPanel>
      <SelectedPlayers/>
    </TabPanel>
    <TabPanel>
      <SearchList/>
    </TabPanel>
  </TabPanels>
</Tabs> 
: 
<VStack height={params.height - header_height}> { /* whole page */}
<Box width={'90%'} height={title_box_height}> {/* box ith only the title of the page */}
  <Center>
<Text as={'b'} fontSize={'xl'}>
  Lichess notifications
</Text>

</Center>

</Box>

<SimpleGrid
  columns={2}
  p={5}
  // height={params.height - title_box_height - header_height - 20}
>
  {/* height without the title */}
  <SearchList isMobile={params.isMobile} height={params.height}/>
  <SelectedPlayers isMobile={params.isMobile} height={params.height}/>
</SimpleGrid>
</VStack>
}           
  </>      
  );
}