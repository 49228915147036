import React from 'react'
import { useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom"
import { useCookies } from 'react-cookie'
import axios from 'axios';
import { Heading, Text, Box, Button, Center, Input, Stack, Flex, useToast } from '@chakra-ui/react'


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


function NewPasswordPage(){

  const toast = useToast()
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  let {verificationtext} = useParams()
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(true)
  const [writtenPassword, setWrittenPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  async function sendNewPass(){
    if(writtenPassword.length>=8){
      setErrorText('')
  let req = await axios.post('https://api.noteefy.net/newpass/', [verificationtext, writtenPassword] )
  if(await req.data == true)
  {
    toast({
      title: 'Your password has been reset.',
      description: 'You have successfully reset your password, go to the Account tab and try to log in.',
      status: 'success',
      duration: 3000,
      isClosable: true
    })

  }else{
    toast({
      title: 'The reset link has expired.',
      description: 'The reset link has expired. Go to the Account tab and click the "Forgot password" button once again.',
      status: 'info',
      duration: 5000,
      isClosable: true
    })
  }
  }else{
    setErrorText('Password must be at least 8 letters long.')
  }
  }
  
  const handlePasswordChange = (new_val) => setWrittenPassword(new_val.target.value);

  useEffect(()=>{
    if(windowHeight.current < windowWidth.current){
      setIsMobile(false)
    }
    else {setIsMobile(true)}
  }, [windowSize])

  
  return(
    <Center height={'80%'}>
  <Center>
        <Box p={10}
        height={300}
        width={600}
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md">
  <Stack
    flexDir="column"
    mb="2"
    justifyContent="center"
    alignItems="center"
  >
     <Heading size='lg'> Write a new password</Heading>
    <Box minW={{ base: "70%", md: "468px" }}>
        <Stack
          spacing={4}
          p="1rem"
          backgroundColor="whiteAlpha.900"
          boxShadow="md"
        >
              <Input type="password" 
              placeholder="your new password" 
              onChange={handlePasswordChange}/>
            <Button
            onClick={sendNewPass}
            borderRadius={8}
            variant="solid"
            backgroundColor='#F2E7D5'
            width="full" 
          >
            Reset password
          </Button>
        </Stack>
    </Box>
    <Flex
        width={'100%'}>
          
        <Text color={'red'}>
          {errorText}
        </Text>
        </Flex>
        
        <Text>
        Write in a new password and click the "Reset password" button
        </Text>
      
    </Stack>
    </Box>
  
    </Center>
    </Center>
    
  )}

export default NewPasswordPage
