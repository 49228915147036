import React from 'react'
import { useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom"
import {Link as RouteLink} from "react-router-dom";
import { Heading, Box, Link, Text, Button,
HStack,
Center
} from '@chakra-ui/react'

function TopHeading(params) {
  return (
    <Box maxW="2xl" m="0 auto">
      <Heading as="h1" textAlign="center" fontSize="4xl" mt="50px">
        {params.text}
      </Heading>
    </Box>
  )
}

const NavLink = ({ text }) => (
  <Link>
    <Text fontSize="sm">{text}</Text>
  </Link>
);

function LinkButton({text, link}){
  return(<Button backgroundColor='#F2E7D5' height='8'>
        <RouteLink to={link}>
          <NavLink text={text} />
        </RouteLink>
        </Button>)
}


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


function VerifyPage(){
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  let {success} = useParams()
  let success_text = 'unsuccessful'
  if (success == 'true'){
    success_text = 'successful'
  }
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(true)



  useEffect(()=>{
    if(windowHeight.current < windowWidth.current){
      setIsMobile(false)
    }
    else {setIsMobile(true)}
  }, [windowSize])

  
  return(
  <>
  <Center height={500}>
  <Box p={5}
        width={isMobile ? '90%' : '50%'}
        backgroundColor={'brand.lightSmallElement'}
        borderRadius={10}
        boxShadow="md">
        
      <Heading align='center' size={'md'}>Verification was {success_text}</Heading>
      <br />
      <br />
      <br />
      To login, go to <LinkButton text='Account' link='/account' /> page.

      <br />
      To learn how to start using Noteefy, go to <LinkButton text='Quickstart' link='/quickstart' /> page.
      <br />
      <br />
      Thank you for choosing Noteefy!
    </Box>
    
    </Center>
  </>
  )}

export default VerifyPage
