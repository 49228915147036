import React, { useCallback } from 'react'
import { useState, useRef, useEffect} from "react";
import { useCookies } from 'react-cookie'
import {Link as RouteLink} from "react-router-dom";

import axios from 'axios';
import  PlayerBadge from "../../../src/PlayerBadge"
import { Link, Text, Heading, Image, Box, Button, Spacer,
VStack,
HStack,
Center,
Container,
} from '@chakra-ui/react'

function TopHeading(params) {
  return (
    <Box maxW="2xl" m="0 auto">
      <Heading as="h1" textAlign="center" fontSize="4xl" mt="50px">
        {params.text}
      </Heading>
    </Box>
  )
}
const NavLink = ({ text }) => (
  <Link>
    <Text fontSize="sm">{text}</Text>
  </Link>
);

function LinkButton({text, link}){
  return(<Button backgroundColor='#F2E7D5' height='8'>
        <RouteLink to={link}>
          <NavLink text={text} />
        </RouteLink>
        </Button>)
}
const getPreview = async function(site, username, func){
  let req = await axios.get('https://api.noteefy.net/badgepreview/', {
    params: {
      username: username,
      site: site
    }
  })  
  func(req.data)
}

const GenericPreviewBadge = ({username, isMobile, window_width, chess_site, showBadge})=>{
  const [previewData, setPreviewData] = useState(null)
  const [tv_link, set_tv_link] = useState('')
  const [profile_link, set_profile_link] = useState('')

  useEffect( ()=>{
    getPreview(chess_site, username, setPreviewData)
  }, [])

  useEffect(() => {
    if (chess_site == 'Lichess'){
      set_tv_link('https://lichess.org/@/' + username + '/tv')
      set_profile_link('https://lichess.org/@/' + username)
    }
    else{
      //chesscom
      set_tv_link('https://www.chess.com/play/online?action=follow&member=' + username)
      set_profile_link('https://www.chess.com/member/' + username)
    }
  }, [chess_site, username])

  if (!previewData){
    return null
  }

  return(
    <PlayerBadge
      isMobile ={isMobile}
      handleUpdateBadges = {null}
      badges={null}
      window_width = {window_width}
      nick={previewData['username']} 
      firstName={previewData['firstName']} 
      lastName={previewData['lastName']}  
      title={previewData['title']}  
      createdAt={previewData['createdAt']}  
      lastSeen={previewData['seenAt']}
      data={previewData}
      showBadge={showBadge}
      allGames={previewData['allGames']}
      ratedGames={previewData['ratedGames']}
      tv_link={tv_link}
      profile_link={profile_link}
      chess_site={chess_site}
    />
  )
}

const PreviewBadge = ({data, isMobile, window_width, chess_site, tv_link, profile_link})=>{
  useEffect(() => {
  }, [data]);
  
  if (data){
    return(
      <PlayerBadge
            isMobile ={isMobile}
            handleUpdateBadges = {null}
            badges={null}
            window_width = {window_width}
            nick={data['username']} 
            firstName={data['firstName']} 
            lastName={data['lastName']}  
            title={data['title']}  
            createdAt={data['createdAt']}  
            lastSeen={data['seenAt']}
            data={data}
            showBadge={!isMobile}
            allGames={data['allGames']}
            ratedGames={data['ratedGames']}
            tv_link={tv_link}
            profile_link={'https://lichess.org/@/drnykterstein'}
            chess_site={chess_site}
            />
        )
  }
  else return null
  
}

function HomePage(params){
  const windowWidth = useRef(window.innerWidth).current;
  const windowHeight = useRef(window.innerHeight).current;
  const [previewData_0, setPreviewData_0] = useState(false)
  const [previewData_1, setPreviewData_1] = useState(false)

  function QuickstartText(){
    return(
      <Text align={'center'}>To learn how to start using Noteefy, go to <LinkButton text='Quickstart' link='/quickstart' /> page.</Text>
    )
  }

  

  function IntroText({width, height}){
    const scrollContainerRef = useRef(null);
    useEffect(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    }, []);

    return(
  
      <Box p={3}
      width={params.isMobile? '100%' : width}
      backgroundColor={'brand.badgeBackground'}
      borderRadius={10}
      boxShadow="md">
      
    <TopHeading text={"Noteefy"} />
    {params.isMobile? null :<br /> }
    <br />
    Welcome to Noteefy! We are here to notify you whenever one of your favorite players or streamers starts an online chess game.
    With Noteefy, you'll never miss a chance to watch and learn from the best in the world.
    If you are a fan of Magnus Carlsen, Eric Rosen, or any other top chess player or streemer, Noteefy is a great way to never miss their games.
    <br />
    <br />
    Just add them to watched players and wait for a notification:
    <br />
    <br />
    <Container width={params.isMobile ? '70%' : '50%'}>
    <Image src={'https://api.noteefy.net/static/Magnus_playing.png'}/>
    </Container>
    {params.isMobile == true? <QuickstartText/> : null}
    <br />
    <VStack p={3}>
    {params.isMobile ?
    <>
    <br/>
    <br/>
    <Heading size='sm'>Use Noteefy to create your favourite players list:</Heading>
    <GenericPreviewBadge 
      username={'DrNykterstein'}
      isMobile={params.isMobile} 
      window_width={windowWidth} 
      chess_site='Lichess'
      showBadge={false}
      />
    <GenericPreviewBadge 
      username={'IMRosen'}
      isMobile={params.isMobile} 
      window_width={windowWidth} 
      chess_site='Chesscom'
      showBadge={false}
      />
    </>: null}
    {params.isMobile ? 
    <GenericPreviewBadge 
    username={'Hikaru'}
    isMobile={params.isMobile} 
    window_width={windowWidth} 
    chess_site='Chesscom'
    showBadge={false}
    />: null}
    <br/>
    {params.isMobile? <Heading size='sm'>And configure your notifications settings</Heading> : null}

    </VStack>
    <br/>
    {params.isMobile == false? <QuickstartText/> : null}
    <br/>
    
  </Box>
    )
  }

  useEffect( ()=>{
    getPreview('Lichess', 'DrNykterstein', setPreviewData_0)
    getPreview('Chesscom', 'Hikaru', setPreviewData_1)
  }, [])
  
  return(
    params.isMobile?
  <IntroText/>
    :
    <HStack p={5} >
    <IntroText width={'100%'}/>
    <Spacer/>
      <Center height={windowHeight-300} overflowY="auto" width={'100%'}>
        <VStack width={'100%'} p={10} height={windowHeight-300}>
          <Heading size='md'>Use Noteefy to create your favourite players list:</Heading>
        <br/>
        <br/>
        <GenericPreviewBadge 
          username={'DrNykterstein'}
          isMobile={params.isMobile} 
          window_width={windowWidth} 
          chess_site='Lichess'
          showBadge={false}
        />
        <GenericPreviewBadge 
          username={'Hikaru'}
          isMobile={params.isMobile} 
          window_width={windowWidth} 
          chess_site='Chesscom'
          showBadge={false}
        />
        <GenericPreviewBadge 
          username={'IMRosen'}
          isMobile={params.isMobile} 
          window_width={windowWidth} 
          chess_site='Chesscom'
          showBadge={false}
        />
        <GenericPreviewBadge 
          username={'Yourself'}
          isMobile={params.isMobile} 
          window_width={windowWidth} 
          chess_site='Lichess'
          showBadge={false}
        />
        <GenericPreviewBadge 
          username={'GothamChess'}
          isMobile={params.isMobile} 
          window_width={windowWidth} 
          chess_site='Chesscom'
          showBadge={false}
        />
        <br/>
        <br/>
          <Heading size='md'>And configure your notifications settings</Heading>
        
        </VStack>
        </Center>
    </HStack>
  )}

export default HomePage
